package com.narbase.letsgo.web.routing.domain.admin.bankilytransfers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.bankilytransfers.ExtendedBankilyTransferDto

object GetBankilyTransfersEndpoint : EndPoint<GetBankilyTransfersEndpoint.Request, GetBankilyTransfersEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String,
        val unassigned: Boolean
    )

    class Response(
        val extendedBankilyTransfers: Array<ExtendedBankilyTransferDto>,
        val total: Int
    )
}