package com.narbase.letsgo.web.views.bankilyTransfers

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.bankilytransfers.ExtendedBankilyTransferDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.bankilytransfers.GetBankilyTransfersEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class BankilyTransfersManagementViewModel {

    val uiState = Observable<BasicUiState>()
    val getTransfersUiState = Observable<BasicUiState>()
    val updateUiState = Observable<BasicUiState>()
    var bankilyTransfers: List<ExtendedBankilyTransferDto> = listOf()
    var unassigned: Boolean = false
    var searchTerm = ""


    var pageNo = 0
    var pageSize = 20
    var total = 0
    fun getBankilyTransfers() {
        basicNetworkCall(getTransfersUiState) {
            val dto = GetBankilyTransfersEndpoint.Request(pageNo, pageSize, searchTerm, unassigned)
            val response = GetBankilyTransfersEndpoint.remoteProcess(dto).data
            bankilyTransfers = response.extendedBankilyTransfers.toList()
            total = response.total
        }
    }

    fun searchFor(term: String) {
        searchTerm = term
        pageNo = 0
        uiState.value = BasicUiState.Loaded
        getBankilyTransfers()
    }

    fun getNextPage() {
        pageNo++
        getBankilyTransfers()
    }

    fun getPreviousPage() {
        pageNo--
        getBankilyTransfers()
    }
}