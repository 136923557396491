package com.narbase.letsgo.web.views.bankilyTransfers

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.bankilytransfers.ExtendedBankilyTransferDto
import com.narbase.letsgo.web.dto.drivers.DriverDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.formatDateAndTime
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.user.profile.EditablePhoneNumberView

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/11/12.
 */
class AssignTransferToDriverDialog(
    private val extendedBankilyTransfer: ExtendedBankilyTransferDto,
    private val onAssigned: () -> Unit
) {
    private var assignDriverButtonLayout: LinearLayout? = null
    private var searchByPhoneLayout: LinearLayout? = null
    private val popup by lazy { popUpDialog() }
    private val viewModel = AssignTransfersToDriverViewModel(extendedBankilyTransfer)
    private val phoneEditableView = EditablePhoneNumberView()
    private var amountInput : TextInput? = null
    private var txnIdInput : TextInput? = null
    private var errorTextView: TextView? = null



    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = { popup.dismissDialog() }
                    }
                    horizontalFiller(20)
                    assignDriverButtonLayout = horizontalLayout {
                        horizontalFiller(8)
                        theme.mainButton(this) {
                            text = "Assign to driver".localized()
                            onClick = {
                                viewModel.data?.let { driver -> onAssignPaymentToDriver(driver, true ) }
                            }
                        }
                        verticalFiller(10)

                        theme.mainButton(this) {
                            text = "Assign without payment".localized()
                            onClick = {
                                viewModel.data?.let { driver -> onAssignPaymentToDriver(driver, false) }
                            }
                        }
                    }

                }
            }

            transferDetails(extendedBankilyTransfer)
            if (extendedBankilyTransfer.bankilyTransfer.transactionId == null) {
                manuallyAddNullValues()
            }
            if (extendedBankilyTransfer.driver == null) {
                findDriverLayout()
            }

        }
        onDialogCreated()
    }

    private fun LinearLayout.findDriverLayout() = verticalLayout {
        matchParentWidth()
        style { margin = 10.px }
        theme.bigTextView(this) {
            text = "Assign to a driver".localized()
        }
        verticalFiller(4)
        theme.text(this) {
            text = "Search drivers using phone number".localized()
        }
        verticalFiller(16)
        verticalLayout {
            style {
                width = matchParent
                height = wrapContent
                backgroundColor = Color.white
                alignItems = Alignment.Center
            }
            searchByPhoneLayout = verticalLayout {

                theme.label(this, "Phone number".localized(), true)
                form {
                    element.onsubmit = {
                        it.preventDefault()
                    }
                    horizontalLayout {
                        style {
                            width = matchParent
                            justifyContent = JustifyContent.Center
                        }

                        mount(phoneEditableView)
                        phoneEditableView.enableEdit()

                        horizontalFiller(10)

                        theme.menuButton(this) {
                            element.type = "submit"
                            horizontalLayout {
                                style {
                                    alignSelf = Alignment.Center
                                    padding = 4.px
                                }
                                imageView {
                                    style {
                                        alignSelf = Alignment.Center
                                        width = 12.px
                                        height = 12.px
                                    }
                                    element.src = "/public/img/search.png"
                                }
                                horizontalFiller(8)
                                textView {
                                    text = "Search drivers".localized()
                                }
                            }
                            onClick = {
                                viewModel.searchFor( phoneEditableView.phoneNumber)
                            }
                        }
                    }
                }
            }
            verticalFiller(10)

            verticalLayout {
                matchParentWidth()
                withLoadingAndError(
                    viewModel.uiState,
                    onRetryClicked = viewModel::getDriver,
                    onLoaded = { onListLoaded() }
                )
            }
        }

    }

    private fun LinearLayout.manuallyAddNullValues() = verticalLayout {
        matchParentWidth()
        theme.mediumTextView(this) {
            text = "Add missing data manually "
        }
        theme.label(this, "Amount: ", true)
        amountInput = theme.textInput(this){

        }
        theme.label(this, "Txn ID: ", true)
        txnIdInput = theme.textInput(this){

        }

        errorTextView = textView {
            style {
                marginBottom = 8.px
                fontSize = 14.px
                color = AppColors.redLight
                padding = 20.px
            }
            isVisible = false
            text = "Please enter valid missing values".localized()
        }


    }
    private fun LinearLayout.transferDetails(item: ExtendedBankilyTransferDto) = verticalLayout {
        matchParentWidth()
        verticalLayout {
            style {
                width = matchParent
                padding = 8.px
                borderRadius = 4.px
            }
            theme.label(this, "Txn ID", false)
            theme.bigTextView(this) {
                style { width = matchParent }
                text = item.bankilyTransfer.transactionId ?: ""
            }

            horizontalLayout {
                matchParentWidth()
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Phone Number".localized(), false)
                    theme.text(this) { text = item.bankilyTransfer.driverPhoneNumber ?: "-" }
                    theme.label(this, "Driver", false)
                    theme.text(this) { text = item.driver?.fullName ?: "UnAssigned".localized() }
                }
                horizontalFiller(8)
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Time", false)
                    theme.text(this) { text = item.bankilyTransfer.receivedOn.toDate().formatDateAndTime() ?: "-" }
                }
            }
            theme.label(this, "Amount", false)
            theme.text(this) { text = item.bankilyTransfer.amount?.toString() ?: " - " }
            theme.label(this, "Message", false)
            theme.text(this) { text = item.bankilyTransfer.message }

        }
    }

    private fun onDialogCreated() {
        phoneEditableView.focusOnPhoneNumberInput()
        assignDriverButtonLayout?.isVisible = false
    }

    private fun View.onListLoaded() {
        clearAllChildren()
        val foundItem = viewModel.data
        if (foundItem == null) {
            assignDriverButtonLayout?.isVisible = false
            textView {
                style {
                    width = matchParent
                    paddingHorizontal = 8.px
                    color = AppColors.textLight
                    textAlign = TextAlign.Center
                }
                text = "No driver found".localized()
            }
            verticalFiller(8)

        } else {
            assignDriverButtonLayout?.isVisible = true
            searchByPhoneLayout?.isVisible = true
            populateFoundDriverView(foundItem)
        }
    }

    private fun View.populateFoundDriverView(item: DriverDto) {
        verticalLayout {
            style {
                width = matchParent
                padding = 8.px
                border = "1px solid ${AppColors.borderColor}"
                borderRadius = 4.px
            }
            theme.bigTextView(this) {
                style { width = matchParent }
                text = item.fullName
            }

            horizontalLayout {
                matchParentWidth()
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Email", false)
                    theme.text(this) { text = item.email }
                    theme.label(this, "Phone", false)
                    theme.text(this) { text = item.phone }
                }
                horizontalFiller(8)
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Created", false)
                    theme.text(this) { text = item.createdOn?.toDate()?.formatDateAndTime() ?: "-" }
                }
            }
            theme.label(this, "Notes", false)
            theme.text(this) { text = item.notes ?: "-" }

            onClick = {
//                popup.dismissDialog()
//                onAssigned()
            }
        }
    }

    private fun onAssignPaymentToDriver(driver: DriverDto, makePayment: Boolean) {
        val driverId = driver.id ?: return
        var amount = extendedBankilyTransfer.bankilyTransfer.amount ?: 0.0
        var transactionId = extendedBankilyTransfer.bankilyTransfer.transactionId ?: ""

        if (extendedBankilyTransfer.bankilyTransfer.transactionId == null) {
            val amountText = amountInput?.text
            val txnText = txnIdInput?.text
            if (amountText.isNullOrBlank() || amountText.toDoubleOrNull() == null) {
                errorTextView?.isVisible = true
                return
            } else {
                amount =  amountText.toDouble()
            }
            if (txnText.isNullOrBlank()) {
                errorTextView?.isVisible = true
                return
            } else {
                transactionId =  txnText
            }
        }
        var message =  "Assign Txn ID: ${transactionId}, $amount MRU, to ${driver.fullName}".localized()

        message += if (makePayment) {
            "\nAnd make payment".localized()
        } else {
            "\nWithout making payment.".localized()
        }
        showConfirmationDialogWithRemoteAction(
            title = "Confirm Transfer",
            message = message,
            action = {
                viewModel.assignTransferToDriver(driverId, amount, transactionId, makePayment )
            },
            actionButtonText = "Confirm",
            dismissButtonText = "Dismiss",
            uiState = viewModel.assignToDriverUIState,
            onConfirmed = {
                popup.dismissDialog()
                onAssigned()
            },
            actionButtonStyle = null
        )
    }
}