package com.narbase.letsgo.web.dto.pointsOfIntrest
class PointOfInterestDto(
    val id: Long?,
    val lat: Double,
    val lng: Double,
    val address: String,
    val name: String,
    val nameFr: String,
    val isActive: Boolean,
    val placeTypeId: Long,
    val blockL1Id: Long?,
)

fun PointOfInterestDto.getFullAddress() = "$name, $address"