package com.narbase.letsgo.web.views.bankilyTransfers

import com.narbase.kunafa.core.components.Component
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.PaginationControls
import com.narbase.letsgo.web.utils.views.matchParentDimensions
import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.views.*

class BankilyTransfersManagementComponent : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = BankilyTransfersManagementViewModel()

    private var listTableBody: View? = null

    private val filterUnassigned: Observable<Boolean> = Observable()

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getBankilyTransfers()
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.bankilyTransfers.forEachIndexed { index, item ->
                tableRow {
                    tableCell(item.bankilyTransfer.transactionId ?: "-", 1, 14.px)
                    tableCell(item.bankilyTransfer.driverPhoneNumber ?: "-", 1, 14.px)
                    tableCell(item.bankilyTransfer.amount?.toString() ?: "-", 1, 14.px)
                    tableCell(item.driver?.fullName ?: "Not Assigned".localized(), 1, 14.px)


                    onClick = {
                        AssignTransferToDriverDialog(
                            extendedBankilyTransfer = item,
                            onAssigned = {
                                viewModel.getBankilyTransfers()
                            }
                        ).show()
                    }
                }

                if (index != viewModel.bankilyTransfers.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "BankilyTransfersManagementRootView"
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }

                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Bankily Driver Transfers".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }
                    horizontalFiller()
                    filterUnAssigned(false, filterUnassigned, "Filter by Unassigned".localized())
                    filterUnassigned.observe {
                        it?.let { viewModel.unassigned = it }
                        viewModel.getBankilyTransfers()
                    }
                    horizontalFiller()
                    searchTextInput("Search".localized()) {
                        viewModel.searchFor(it)
                    }
                }

                verticalLayout {
                    matchParentDimensions()
                    withLoadingAndError(viewModel.getTransfersUiState,
                        onRetryClicked = { viewModel.getBankilyTransfers() },
                        onLoaded = { onListLoaded() })

                    listTableBody = listTable {
                        headerCell("Tnx ID".localized(), 1)
                        headerCell(" Driver Phone Number".localized(), 1)
                        headerCell("Amount".localized(), 1)
                        headerCell("Driver", 1)

                    }
                    paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
                }
            }
        }
    }

    private fun LinearLayout.filterUnAssigned(
        isEnabled: Boolean,
        isEnabledSelected: Observable<Boolean>,
        label: String
    ): ToggleSwitch {
        val switch = ToggleSwitch()
        horizontalLayout {
            style { alignItems = Alignment.Center }
            switch.resetIsSelected()
            switch.isSelected.value = isEnabled
            switch.isSelected.observe {
                if (it != null) {
                    isEnabledSelected.value = it
                }
            }
            theme.label(this, label, false)
            mount(switch)
        }
        return switch
    }
}