package com.narbase.letsgo.web.dto.driverprofilegroup

import com.narbase.letsgo.web.dto.flags.FlagDto

class DriverProfileGroupDto(
    val id: Int?,
    val name: String,
    val commission: Double,
    val isCommissionPercentage: Boolean,
    val commissionDetails: CommissionDetailsDto?
)

class ExtendedDriverProfileGroupDto(
    val driverProfileGroup: DriverProfileGroupDto,
    val flag: FlagDto?
)


class CommissionDetailsDto(
    val commissionRanges: Array<CommissionRangeDto>,
)

class CommissionRangeDto(
    val start: Double,
    val end: Double?,
    val commission: Double,
    val isPercentage: Boolean
)