package com.narbase.letsgo.web.views.driverProfileGroups

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.driverprofilegroup.CommissionDetailsDto
import com.narbase.letsgo.web.dto.driverprofilegroup.CommissionRangeDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*

class CommissionDetailsComponent(
    val commissionDetailsDto: CommissionDetailsDto?,
    val onInvalidInput: (String) -> Unit,
) : Component() {


    private var commissionRangeInputs: Observable<MutableList<CommissionRangeInput>> = Observable()
    private val isPercentageArray = arrayOf("Percentage", "Static Value")
    val uiState = Observable<Boolean>()

    private val endRangeString = "More"

    class CommissionRangeInput(
        var startInput: TextInput? = null,
        var endInput: TextInput? = null,
        var commissionInput: TextInput? = null,
        var isCommissionPercentageDropDown: DropDownList<String>
    )

    private var commissionsLayout: View? = null
    private fun populateCommissionRangeInputs() {
        val listInputs = commissionDetailsDto?.commissionRanges?.mapIndexed { index, commissionRangeDto ->
            getCommissionRangeInputs(commissionRangeDto)
        }?.toMutableList()

        if (listInputs?.isEmpty() == true) {
            listInputs.add(getCommissionRangeInputs(null))
        }

        commissionRangeInputs.value = listInputs?.toMutableList() ?: mutableListOf(getCommissionRangeInputs(null))
    }
    override fun View?.getView() = verticalLayout {
        matchParentWidth()
        mainLayout()
    }


    private fun LinearLayout.mainLayout() = verticalLayout {
        matchParentWidth()
        style {
            alignSelf = Alignment.Center
        }

        theme.mediumTextView(this) {
            text = "Commission Details in Ranges".localized()
        }
        commissionsLayout = verticalLayout {
            style {
                width = matchParent
            }

            commissionRangeInputs.observe {
                clearAllChildren()

                it?.forEachIndexed { index, commissionRangeInput ->
                    commissionRangeLayout(index, commissionRangeInput)

                }
            }
            if (commissionDetailsDto?.commissionRanges.isNullOrEmpty()) {
                addCommissionInput(null, null)
            }

        }


        verticalFiller(20)
        populateCommissionRangeInputs()

    }

    private fun onSplit(index: Int, commissionRange: CommissionRangeDto) {
        addCommissionInput(index, commissionRange)
    }

    private fun addCommissionInput(index: Int?, commissionRange: CommissionRangeDto?) {
        val list = commissionRangeInputs.value
        val commissionRangeInput = getCommissionRangeInputs(commissionRange)
        if (index != null) {
            list?.add(
                index,
                commissionRangeInput
            )
        } else {
            list?.add(commissionRangeInput)
        }
        commissionRangeInputs.value = list

    }

    fun getCommissionRangeInputs(commissionRange: CommissionRangeDto?): CommissionRangeInput {
        val startInput = TextInput()
        val endInput = TextInput()
        val commissionInput = TextInput()
        val isCommissionPercentageDropDown: DropDownList<String> = DropDownList(
            name = "",
            items = isPercentageArray,
            itemToString = { it },
            defaultValue = commissionRange?.let { it.isPercentage.toIsCommissionPercentageString() },
            onItemSelected = {},
            dropListStyle = DropDownList.DropDownListStyle(),
            viewWidth = 300.px,
            slug = null,
            controller = DropDownListViewController()
        )
        startInput.text = commissionRange?.start?.toString() ?: ""
        endInput.text = commissionRange?.end?.toString() ?: ""
        commissionInput.text = commissionRange?.commission?.toString() ?: ""

        if (commissionRange == null) {
            val listSize = commissionRangeInputs.value?.size
            if (listSize == 0 || listSize == null) {
                startInput.text = 0.0.toString()
            }
        }
        return CommissionRangeInput(
            startInput = startInput,
            endInput = endInput,
            commissionInput = commissionInput,
            isCommissionPercentageDropDown = isCommissionPercentageDropDown
        )


    }

    private fun LinearLayout.commissionRangeLayout(
        index: Int,
        commissionRangeInput: CommissionRangeInput
    ) =
        horizontalLayout {
            style {
                width = matchParent
                order = index.toString()
            }
            val start = commissionRangeInput.startInput?.text
            val end = commissionRangeInput.endInput?.text
            val commission = commissionRangeInput.commissionInput?.text ?: ""
            verticalLayout {
                theme.label(this, "Start Price: ", false)

                commissionRangeInput.startInput = theme.textInput(this) {
                    style {
                        width = 110.px

                    }
                    placeholder = ""
                    type = "number"
                    element.disabled = true

                }
                if (!start.isNullOrBlank()) {
                    commissionRangeInput.startInput?.element?.value = start
                } else {
                    if (index > 0) {
                        val previousEnd =
                            commissionRangeInputs.value?.get(index - 1)?.endInput?.text?.toDoubleOrNull() ?: 0.0
                        if (previousEnd != null) {
                            commissionRangeInput.startInput?.element?.value = previousEnd.toString()
                        }

                    }
                }

            }
            horizontalFiller()
            verticalLayout {
                theme.label(this, "End Price: ", false)
                commissionRangeInput.endInput = theme.textInput(this) {
                    style {
                        width = 110.px
                    }
                    placeholder = ""
                    type = "number"
                    element.oninput = {
                        val listSize = commissionRangeInputs.value?.size
                        if (listSize != index + 1) {
                            commissionRangeInputs.value?.get(index + 1)?.startInput?.element?.value = this.text

                        }
                    }
                }
                if (!end.isNullOrBlank() && end != endRangeString) {
                    commissionRangeInput.endInput?.element?.value = end.toString()
                } else {
                    val listSize = commissionRangeInputs.value?.size
                    if (listSize == index + 1) {
                        commissionRangeInput.endInput?.element?.type = "text"
                        commissionRangeInput.endInput?.element?.value = endRangeString
                        commissionRangeInput.endInput?.element?.disabled = true
                    }
                }

            }
            horizontalFiller()
            verticalLayout {
                theme.label(this, "Commission: ".localized(), false)
                commissionRangeInput.commissionInput = theme.textInput(this) {
                    style {
                        width = 110.px
                    }
                    placeholder = ""
                }
            }
            if (commission != null) {
                commissionRangeInput.commissionInput?.element?.value = commission
            }
            horizontalFiller()
            verticalLayout {
                theme.label(this, "Commission in: ", false)
                mount(commissionRangeInput.isCommissionPercentageDropDown)
            }
            horizontalFiller()


            verticalLayout {
                style {
                    height = matchParent
                    justifyContent = JustifyContent.Center
                    alignContent = Alignment.Center
                }
                materialIcon("delete") {
                    style {
                        opacity = 0.4
                        pointerCursor()
                        hover {
                            color = Color.red
                        }
                    }
                    isVisible = index != 0
                    onClick = {
                        this@horizontalLayout.isVisible = false
                        val listSize = commissionRangeInputs.value?.size ?: 1
                        if (listSize == index + 1) {
                            commissionRangeInputs.value?.get(index - 1)?.endInput?.element?.type = "text"
                            commissionRangeInputs.value?.get(index - 1)?.endInput?.element?.value = endRangeString
                            commissionRangeInputs.value?.get(index - 1)?.endInput?.element?.disabled = true
                        } else {
                            val currentStart = commissionRangeInput.startInput?.text?.trim()
                            commissionRangeInputs.value?.get(index + 1)?.startInput?.element?.value = currentStart ?: ""
                        }
                        commissionRangeInputs.value?.removeAt(index)
                        refreshInputsList()


                    }
                }

                materialIcon("call_split") {
                    style {
                        opacity = 0.4
                        pointerCursor()
                        hover {
                            color = AppColors.activeColor
                        }
                        transform = "rotate(-90deg)"
                    }
                    onClick = {
                        val currentStart = commissionRangeInput.startInput?.text?.trim()?.toDoubleOrNull()
                        val currentEnd = commissionRangeInput.endInput?.text?.trim()?.toDoubleOrNull()
                        val currentCommission =
                            commissionRangeInput.commissionInput?.text?.trim()?.toDoubleOrNull() ?: 0.0

                        val isEndRange = commissionRangeInput.endInput?.text?.trim() == endRangeString

                        if ((currentEnd != null || isEndRange) && currentStart != null) {
                            if (commissionRangeInput.endInput?.text?.trim() == endRangeString) {
                                val difference = 20
                                val nextStart = currentStart + (difference / 2)
                                val nextEnd = currentEnd
                                commissionRangeInput.endInput?.element?.value = nextStart.toString()

                            }
                            val difference = if (!isEndRange) currentEnd?.minus(currentStart) ?: 20.0 else 20.0
                            val nextStart = currentStart + (difference.div(2))
                            val nextEnd = currentEnd
                            commissionRangeInput.endInput?.element?.value = nextStart.toString()

                            onSplit(
                                index + 1, CommissionRangeDto(
                                    start = nextStart,
                                    end = nextEnd,
                                    commission = currentCommission,
                                    isPercentage = commissionRangeInput.isCommissionPercentageDropDown.getSelectedItem() == isPercentageArray[0]
                                )
                            )
                        }
                    }
                }


            }
        }


    private fun refreshInputsList() {
        val list = commissionRangeInputs.value
        commissionRangeInputs.value = list
    }

    fun save(): Array<CommissionRangeDto>? {
        return commissionRangeInputs.value?.mapIndexed { index, it ->
            val startPrice = it.startInput?.text?.trim()?.toDoubleOrNull()
            val endPrice = it.endInput?.text?.trim()?.toDoubleOrNull()
            val commission = it.commissionInput?.text?.trim()?.toDoubleOrNull()
            val errorMessage = "Please fill all commission fields"
            val isPercentage = it.isCommissionPercentageDropDown.getSelectedItem()
            if (startPrice == null) {
                onInvalidInput("Please enter a valid end price")
                return null
            }
            val listSize = commissionRangeInputs.value?.size ?: 1
            if (endPrice == null && (index != listSize - 1)) {
                onInvalidInput("Please enter a valid end price")
                return null
            } else if (endPrice != null) {
                if (endPrice < startPrice && (index != listSize + 1)) {
                    onInvalidInput("End price can't be less than start price")
                    return null
                }
            }
            if (commission == null) {
                onInvalidInput("Please enter a valid commission")
                return null
            }
            if (isPercentage == null) {
                onInvalidInput(errorMessage)
                return null
            }
            CommissionRangeDto(
                start = startPrice,
                end = endPrice,
                commission = commission,
                isPercentage = isPercentage == isPercentageArray[0]
            )
        }?.toTypedArray()
    }

    fun getCommissionDetails(): CommissionDetailsDto? {
        val commissionRanges = save()

        return if (commissionRanges != null) {
            CommissionDetailsDto(
                commissionRanges = commissionRanges
            )

        } else null
    }

    private fun Boolean?.toIsCommissionPercentageString(): String {
        this?.let {
            if (!it) {
                return isPercentageArray[1]
            }
        }
        return isPercentageArray[0]
    }
}