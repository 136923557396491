package com.narbase.letsgo.web.routing.domain.admin.placetype

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.placetype.PlaceTypeDto

object GetPlaceTypesEndpoint : EndPoint<GetPlaceTypesEndpoint.Request, GetPlaceTypesEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
    )

    class Response(
        val placeTypes: Array<PlaceTypeDto>,
        val total: Int
    )
}