package com.narbase.letsgo.web.routing.domain.admin.bankilytransfers

import com.narbase.letsgo.router.EndPoint

object AssignTransferToDriverEndpoint : EndPoint<AssignTransferToDriverEndpoint.Request, AssignTransferToDriverEndpoint.Response>() {
    class Request(
        val driverId: Long,
        val bankilyTransferId: Long,
        val amount: Double,
        val transactionId: String,
        val makePayment: Boolean
    )

    class Response()
}