package com.narbase.letsgo.web.routing.domain.admin.areas

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.areas.AreaDto

object GetAreasEndpoint : EndPoint<GetAreasEndpoint.Request, GetAreasEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
    )

    class Response(
        val areas: Array<AreaDto>,
        val total: Int
    )
}