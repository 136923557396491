package com.narbase.letsgo.web.views.driverTargets

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.web.dto.drivertargets.ExtendedTargetDto
import com.narbase.letsgo.web.dto.drivertargets.TargetDto
import com.narbase.letsgo.web.models.TargetsStatus
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.dto
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.drivertargets.GetTargetsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.drivertargets.UpsertTargetEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class TargetsManagementViewModel {

    val uiState = Observable<BasicUiState>()
    val getTargetsUiState = Observable<BasicUiState>()
    val updateUiState = Observable<BasicUiState>()
    var targetsList : List<ExtendedTargetDto> = listOf()
    var targetsStatus: DtoName<TargetsStatus>? = null


    var pageNo = 0
    var pageSize = 20
    var total = 0
    fun getTargets(){
        basicNetworkCall(getTargetsUiState) {
            val dto = GetTargetsEndpoint.Request(pageNo, pageSize, targetsStatus)
            val response = GetTargetsEndpoint.remoteProcess(dto).data
            targetsList = response.extendedTargets.toList()
            total = response.total
        }
    }

    fun upsertTarget(target: TargetDto, pastIsAutoRepeat: Boolean){
        val isAutoRepeatChanged = (pastIsAutoRepeat != target.isAutoRepeat)
        basicNetworkCall(updateUiState) {
            val dto = UpsertTargetEndpoint.Request(target, isAutoRepeatChanged)
            val response = UpsertTargetEndpoint.remoteProcess(dto).data
        }

    }

    fun searchFor(term: String) {
        pageNo = 0
        uiState.value = BasicUiState.Loaded
        getTargets()
    }

    fun getNextPage() {
        pageNo++
        getTargets()
    }

    fun getPreviousPage() {
        pageNo--
        getTargets()
    }
}