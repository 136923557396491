package com.narbase.letsgo.web.routing.domain.admin.drivers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.drivers.DriverDto


object GetDriverByPhoneEndpoint : EndPoint<GetDriverByPhoneEndpoint.Request, GetDriverByPhoneEndpoint.Response>() {
    class Request(
        val phone: String,
    )

    class Response(
        val driver: DriverDto?,
    )
}