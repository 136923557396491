package com.narbase.letsgo.web.views.bankilyTransfers

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.bankilytransfers.ExtendedBankilyTransferDto
import com.narbase.letsgo.web.dto.drivers.DriverDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.bankilytransfers.AssignTransferToDriverEndpoint
import com.narbase.letsgo.web.routing.domain.admin.drivers.GetDriverByPhoneEndpoint
import com.narbase.letsgo.web.utils.BasicUiState


/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/11/12.
 */
class AssignTransfersToDriverViewModel (
    private var extendedBankilyTransfer : ExtendedBankilyTransferDto
) {

    val assignToDriverUIState = Observable<BasicUiState>()
    val uiState = Observable<BasicUiState>()
    var data: DriverDto? = null
    private var phone = ""

    fun assignTransferToDriver(driverId: Long, amount: Double, transactionId: String, makePayment: Boolean) {
        val bankilyTransferId = extendedBankilyTransfer.bankilyTransfer.id ?: return
        basicNetworkCall(assignToDriverUIState) {
            val dto = AssignTransferToDriverEndpoint.Request(driverId = driverId, bankilyTransferId = bankilyTransferId, amount = amount, transactionId = transactionId, makePayment = makePayment)
            val response = AssignTransferToDriverEndpoint.remoteProcess(dto).data
        }
    }

    fun searchFor( term: String) {
        phone = term
        uiState.value = BasicUiState.Loaded
        getDriver()
    }

    fun getDriver() {
        basicNetworkCall(uiState) {
            val dto = GetDriverByPhoneEndpoint.Request(phone = phone)
            val response = GetDriverByPhoneEndpoint.remoteProcess(dto).data
            data = response.driver
        }
    }

}